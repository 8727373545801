import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/4th-annual-conference/2.jpeg';
import blogd2 from '../../assets/images/2024/4th-annual-conference/1.jpeg';
// import blogd3 from '../../assets/images/2024/senate-committee-commends-mgt/3.jpeg';
// import blogd4 from '../../assets/images/2024/senate-committee-commends-mgt/4.jpeg';
// import blogd5 from '../../assets/images/2024/senate-committee-commends-mgt/5.jpeg';
// import blogd6 from '../../assets/images/2024/senate-committee-commends-mgt/6.jpeg';
// import blogd7 from '../../assets/images/2024/senate-committee-commends-mgt/7.jpeg';
// import blogd8 from '../../assets/images/2024/senate-committee-commends-mgt/8.jpeg';
// import blogd9 from '../../assets/images/2024/senate-committee-commends-mgt/9.jpeg';
// import blogd10 from '../../assets/images/2024/senate-committee-commends-mgt/10.jpeg';
// import blogd11 from '../../assets/images/2024/senate-committee-commends-mgt/11.jpeg';
// import blogd12 from '../../assets/images/2024/senate-committee-commends-mgt/12.jpeg';




import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                The 4th LUTH Scientific Annual Conference:Advancing Cardiovascular Care
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd2} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                Lagos University Teaching Hospital (LUTH) is gearing up to host its
                 4th Scientific Annual Conference on May 29th-30th, 2024. This year's
                  theme, <strong>"Interventional Cardiology: Advancing the Frontiers in 
                  Cardiovascular Care,"</strong> promises to delve into the latest advancements
                   and innovations in the field of cardiovascular medicine. <strong>Lagos University Teaching Hospital (LUTH).</strong>{' '}
                  
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd1} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    {/* <img src={blogd3} height={370}/> */}
                    <p>
                    With a focus on interventional cardiology, the conference aims to 
                    explore groundbreaking techniques, technologies, and strategies 
                    aimed at improving patient outcomes and revolutionizing cardiovascular
                    care.
                      <br />
                      <br />
                      The 4th LUTH Scientific Annual Conference serves as a platform for 
                      healthcare professionals, researchers, and industry experts to come 
                      together, exchange knowledge, and collaborate towards addressing this
                      critical healthcare challenge.
                    </p>
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Nurse Ndidi Enuwa Retires */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                Cardiovascular diseases remain a leading cause of morbidity and mortality 
                globally. In Nigeria, like many parts of the world, the burden of 
                cardiovascular diseases continues to rise, placing immense pressure on 
                healthcare systems and underscoring the urgent need for innovative approaches 
                to prevention, diagnosis, and treatment.

                  <br />
                  {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div> */}
                  <br />
                  The conference will feature keynote presentations, plenary sessions, 
                  panel discussions, and workshops led by the institutes of Cheif Medical 
                  Director(CMD)Dr Wasiu Lanre Adeyemo. Attendees can expect to gain insights 
                  into the latest developments in minimally invasive procedures, novel devices,
                  and emerging therapies for various cardiovascular conditions. The conference
                  will cover a wide spectrum of topics aimed at equipping healthcare providers 
                  with the knowledge and skills needed to deliver state-of-the-art cardiovascular
                  care.
                  <br />
                  {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div> */}
                  <br />
                  The conference will feature keynote presentations, plenary sessions, 
                  panel discussions, and workshops led by renowned experts in the field of 
                  interventional cardiology. Attendees can expect to gain insights into the 
                  latest developments in minimally invasive procedures, novel devices, and 
                  emerging therapies for various cardiovascular conditions. From advancements 
                  in percutaneous coronary interventions to the latest techniques in structural 
                  heart disease interventions, the conference will cover a wide spectrum of 
                  topics aimed at equipping healthcare providers with the knowledge and skills 
                  needed to deliver state-of-the-art cardiovascular care.
                </p>
                
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                LUTH invites healthcare professionals, researchers, academics, students, and 
                industry stakeholders to participate in this exciting and intellectually 
                stimulating event. Together, let us harness the power of innovation, 
                collaboration, and ethical inquiry to advance the frontiers of cardiovascular 
                care and improve health outcomes for all.
                </span>
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p> */}
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Fig 1: Residents at the department before the program */}
                  <br />
                  {/* Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach. */}
                </span>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd10} height={370} width={500} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd11} height={370} />
                  </div>
                </div> */}
                
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Residents dispensing medications in advance for the outreach */}
                </span>
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} width={500} />
                  </div>
                </div> */}
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div>
                <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
